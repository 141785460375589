import React from 'react';

const today = new Date();

function Footer() {

    return (
        <footer className="footer footer-center md:p-8 p-4 bg-base-100 drop-shadow w-full">
            <div>
                <p>
                    Website built by <strong>Liam Burks</strong> using
                </p>
                <div className="h-16 flex items-center gap-2">
                    <span
                        className='p-2 rounded-full border-primary h-16 flex flex-col items-center tooltip tooltip-top'
                        data-tip={"Typescript"}>
                        <img
                            className='h-full'
                            src='/logos/typescript-logo.png'
                            alt={'Typescript Logo'}
                        />
                    </span>
                    <span
                        className='p-2 rounded-full border-primary h-16 flex flex-col items-center tooltip tooltip-top'
                        data-tip={"React JS"}>
                        <img
                            className='h-full'
                            src='/logos/react-logo.png'
                            alt={'React Logo'}
                        />
                    </span>
                    <span
                        className='p-4 rounded-full border-primary h-16 flex flex-col items-center tooltip tooltip-top'
                        data-tip={"Tailwind CSS"}>
                        <img
                            className='h-full'
                            src='/logos/tailwind-logo.svg'
                            alt={'Tailwind Logo'}
                        />
                    </span>
                </div>
                <div className='divider w-full my-0' />
                <p className="text-xs text-base-content text-opacity-25">
                    {today.toLocaleDateString()}
                </p>
            </div>
        </footer>
    );
}

export default Footer;