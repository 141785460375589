import React, { useState } from 'react';

import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

import './app.css';

function App() {
    const [theme, setTheme] = useState<string>('dark');
    console.log(theme);

    return (
        <div data-theme={theme}>
            <Navbar theme={theme} onThemeChange={(theme: string)=>setTheme(theme)}/>

            <Toaster position='top-left' />
            <Routes>
                <Route index element={<Dashboard />} />
            </Routes>

            <Footer />
        </div>
    );
}

export default App;