import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

function CouursesModal() {
    return (
        <>
            <input type="checkbox" id="courses_modal" className="modal-toggle" />

            <div className="modal w-screen">
                <div className="modal-box max-w-7xl py-12 bg-base-200 text-sm w-full">
                    <div className="w-full flex justify-between items-center md:px-8 px-2">
                        <div className="md:flex block items-center w-full">
                            <h3 className="card-title md:mr-2">
                                Bachelor of Engineering
                            </h3>
                            <h3 className="text-lg">
                                Computer Engineering
                            </h3>
                        </div>
                        <label htmlFor="courses_modal" className="btn btn-ghost btn-circle">
                            <XMarkIcon className={"w-5 h-5"}/>
                        </label>
                    </div>

                    <div className='flex flex-col w-full justify-start items-start collapse hover:bg-base-300'>
                        <span className='collapse-title font-bold btn btn-ghost text-base'>
                            Year 1
                        </span>
                        <input type="checkbox" className="absolute w-full h-full" defaultChecked={true}/>
                        <div className='divider my-0' />
                        <div className='w-full flex items-center justify-center collapse-content'>
                            <div className={"grid grid-cols-2 w-full lg:p-4 p-0 gap-4"}>
                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 1
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    WTW 158
                                                </p>
                                                <p className="w-full break-all">
                                                    Calculus
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    COS 132
                                                </p>
                                                <p className="w-full break-all">
                                                    Imperative Programming
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EBN 111
                                                </p>
                                                <p className="w-full break-all">
                                                    Electricity and Electronics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    FSK 116
                                                </p>
                                                <p className="w-full break-all">
                                                    Physics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    UPO 112
                                                </p>
                                                <p className="w-full break-all">
                                                    Academic Orientation
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    HAS 110
                                                </p>
                                                <p className="w-full break-all">
                                                    Humanities and Social Sciences
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    NMC 113
                                                </p>
                                                <p className="w-full break-all">
                                                    Materials Science
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 2
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    WTW 164
                                                </p>
                                                <p className="w-full break-all">
                                                    Mathematics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    COS 110
                                                </p>
                                                <p className="w-full break-all">
                                                    Introduction to Program Design
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    COS 222
                                                </p>
                                                <p className="w-full break-all">
                                                    Operating Systems
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    SWK 122
                                                </p>
                                                <p className="w-full break-all">
                                                    Mechanics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    HAS 120
                                                </p>
                                                <p className="w-full break-all">
                                                    Humanities and Social Sciences
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EMR 101
                                                </p>
                                                <p className="w-full break-all">
                                                    Introduction to Laboratory Measurements and Computer Simulations
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EIW 121
                                                </p>
                                                <p className="w-full break-all">
                                                    Information Technology Practice
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-full justify-start items-start collapse hover:bg-base-300 p-2'>
                        <span className='collapse-title font-bold btn btn-ghost text-base'>
                            Year 2
                        </span>
                        <input type="checkbox" className="absolute w-full h-full" defaultChecked={true}/>
                        <div className='divider my-0' />
                        <div className='w-full flex items-center justify-center collapse-content'>
                            <div className={"grid grid-cols-2 w-full lg:p-4 p-0 gap-4"}>
                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 1
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    WTW 256
                                                </p>
                                                <p className="w-full break-all">
                                                    Differential Equations
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    WTW 258
                                                </p>
                                                <p className="w-full break-all">
                                                    Calculus
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    BES 220
                                                </p>
                                                <p className="w-full break-all">
                                                    Engineering Statistics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    ELI 220
                                                </p>
                                                <p className="w-full break-all">
                                                    Linear Systems
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    ERS 220
                                                </p>
                                                <p className="w-full break-all">
                                                    Digital Systems
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EJJ 210
                                                </p>
                                                <p className="w-full break-all">
                                                    Professional and Technical Communication
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 2
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    WTW 238
                                                </p>
                                                <p className="w-full break-all">
                                                    Mathematics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    WTW 263
                                                </p>
                                                <p className="w-full break-all">
                                                    Numerical Methods
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    COS 212
                                                </p>
                                                <p className="w-full break-all">
                                                    Data Structures and Algorithms
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EIW 221
                                                </p>
                                                <p className="w-full break-all">
                                                    Information Technology Practice
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EIR 211
                                                </p>
                                                <p className="w-full break-all">
                                                    Electrical Engineering
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    JCP 203
                                                </p>
                                                <p className="w-full break-all">
                                                    Community Based Project
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-full justify-start items-start collapse hover:bg-base-300 p-2'>
                        <span className='collapse-title font-bold btn btn-ghost text-base'>
                            Year 3
                        </span>
                        <input type="checkbox" className="absolute w-full h-full" defaultChecked={true}/>
                        <div className='divider my-0' />
                        <div className='w-full flex items-center justify-center collapse-content'>
                            <div className={"grid grid-cols-2 w-full lg:p-4 p-0 gap-4"}>
                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 1
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EMK 310
                                                </p>
                                                <p className="w-full break-all">
                                                    Microprocessors
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    ENE 310
                                                </p>
                                                <p className="w-full break-all">
                                                    Analogue Electronics
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EME 310
                                                </p>
                                                <p className="w-full break-all">
                                                    Electromagnetic Compatability
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EDC 310
                                                </p>
                                                <p className="w-full break-all">
                                                    Digital Communication
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    BSS 310
                                                </p>
                                                <p className="w-full break-all">
                                                    Engineering Management
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 2
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EPE 321
                                                </p>
                                                <p className="w-full break-all">
                                                    Software Engineering
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EAI 320
                                                </p>
                                                <p className="w-full break-all">
                                                    Intelligent Systems
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    ERD 320
                                                </p>
                                                <p className="w-full break-all">
                                                    Computer Engineering Design
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EBB 320
                                                </p>
                                                <p className="w-full break-all">
                                                    Control Systems
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EIW 320
                                                </p>
                                                <p className="w-full break-all">
                                                    Information Technology Practice
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    MIA 320
                                                </p>
                                                <p className="w-full break-all">
                                                    Engineering Activity and Group Work
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-full justify-start items-start collapse hover:bg-base-300 p-2'>
                        <span className='collapse-title font-bold btn btn-ghost text-base'>
                            Year 4
                        </span>
                        <input type="checkbox" className="absolute w-full h-full" defaultChecked={true}/>
                        <div className='divider my-0' />
                        <div className='w-full flex items-center justify-center collapse-content'>
                            <div className={"grid grid-cols-2 w-full lg:p-4 p-0 gap-4"}>
                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 1
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    IPI 410
                                                </p>
                                                <p className="w-full break-all">
                                                    Engineering Professionalism
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EHN 410
                                                </p>
                                                <p className="w-full break-all">
                                                    e-Business and Network Security
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EAS 410
                                                </p>
                                                <p className="w-full break-all">
                                                    Computer Engineering: Architecture and Systems
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    ESP 411
                                                </p>
                                                <p className="w-full break-all">
                                                    DSP Programming and Application
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="w-full flex flex-col items-start justify-start lg:col-span-1 col-span-2 gap-2">
                                    <h4 className="badge badge-primary font-bold">
                                        Semester 2
                                    </h4>
                                    <ul className="list-disc mt-2 md:pl-8 pl-4">
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EPY 423
                                                </p>
                                                <p className="w-full break-all">
                                                    Practical Training and Report
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    ERP 420
                                                </p>
                                                <p className="w-full break-all">
                                                    Research Project
                                                </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="flex items-center">
                                                <p className="font-bold w-20 mr-2 font-mono">
                                                    EPR 402
                                                </p>
                                                <p className="w-full break-all">
                                                    Final Year Project
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CouursesModal;