export function checkLightTheme(daisyTheme: string) {
    return ["light"].includes(daisyTheme);
}

export const muted_border = "border border-base-content border-opacity-15";

const AnimatedBorder = ({ theme= "light", withGlow = true }) => {

    return (
        <>
            <span
                className={`ease-in-out absolute bottom-0 left-0 h-0 w-0 border-l border-primary border-opacity-50 transition-all duration-1000 delay-200 group-hover:h-full shadow-[0px_0px_5px_0.0001px_rgba(0,0,0,1)] shadow-primary`}
></span>
    <span
    className={`ease-in-out absolute left-0 top-0 h-0 w-0 border-t border-primary border-opacity-50 transition-all duration-1000 delay-200 group-hover:w-full  shadow-[0px_0px_5px_0.0001px_rgba(0,0,0,1)] shadow-primary`}
></span>
    <span
    className={`ease-in-out absolute right-0 top-0 h-0 w-0 border-r border-primary border-opacity-50 transition-all duration-1000 delay-200 group-hover:h-full  shadow-[0px_0px_5px_0.0001px_rgba(0,0,0,1)] shadow-primary`}
></span>
    <span
    className={`ease-in-out absolute bottom-0 right-0 h-0 w-0 border-b border-primary border-opacity-50 transition-all duration-1000 delay-200 group-hover:w-full  shadow-[0px_0px_5px_0.0001px_rgba(0,0,0,1)] shadow-primary`}
></span>
    </>
);
};

export default AnimatedBorder;