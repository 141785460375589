import React from 'react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';

function SkillsGrid() {
    return (
        <div className='grid w-full grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8'>
            <div className="col-span-full font-bold text-sm md:text-base">
                Key Skills
            </div>

            <div className="flex flex-col items-start justify-center">
                <span className="font-bold text-xs">
                    Languages
                </span>
                <p className="flex-wrap break-words text-xs">
                    Typescript, Python, Java, PHP, C++, Javascript, CSS & HTML
                </p>
            </div>

            <div className="flex flex-col items-start justify-center">
                <span className="font-bold text-xs">
                    Frameworks
                </span>
                <p className="flex-wrap break-words text-xs">
                    React, Node, Django, Spring, Tailwind, Vue, Laravel, Next
                </p>
            </div>

            <div className="flex flex-col items-start justify-center">
                <span className="font-bold text-xs">
                    Architectures
                </span>
                <p className="flex-wrap break-words text-xs">
                    Model-View-Controller, Object Oriented Programming, Client-Server, Layered Pattern, Functional
                    Programming, Dependency Injection
                </p>
            </div>

            <div className="flex flex-col items-start justify-center">
                <span className="font-bold text-xs">
                    Database & Data Transfer
                </span>
                <p className="flex-wrap break-words text-xs">
                    SQL (PostgreSQL, MySQL, MSSQL), NoSQL (ElasticSearch, OpenSearch), REST, GraphQL
                </p>
            </div>

            <div className="flex flex-col items-start justify-center">
                <span className="font-bold text-xs">
                    Cloud
                </span>
                <p className="flex-wrap break-words text-xs">
                    AWS (RDS, EC2, OpenSearch, Beanstalk, Lambda, Route53, Step Functions, Textract)
                </p>
            </div>

            <div className="flex flex-col items-start justify-center">
                <span className="font-bold text-xs">
                    Management
                </span>
                <p className="flex-wrap break-words text-xs">
                    Client engagement, interpersonal communication, leadership, effective delegation, scope management, technical communication
                </p>
            </div>
        </div>
    );
}

export default SkillsGrid;