import React, { useState } from 'react';
import {
    MoonIcon,
    PhoneIcon,
    SunIcon,
} from '@heroicons/react/20/solid';

import { copyToClipboard } from '../utils/utils';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

interface NavbarProps {
    theme: string,
    onThemeChange: Function
}

function Navbar(props: NavbarProps) {
    return (
        <div className='w-full flex justify-center bg-base-100 drop-shadow z-50'>
            <div className='navbar bg-base-100 max-w-7xl'>
                <div className='flex-1 flex items-center justify-start gap-1'>
                    <div
                        className='btn btn-ghost'
                        onClick={() => copyToClipboard('+27834176895')}
                    >
                        <PhoneIcon className='w-5 h-5' />
                        <p className='md:block hidden text-sm font-light'>
                            +27 83 417 6895
                        </p>
                    </div>

                    <div
                        className='btn btn-ghost'
                        onClick={() => copyToClipboard('burks.liam@gmail.com')}
                    >
                        <EnvelopeIcon className='w-5 h-5' />
                        <p className='md:block hidden text-sm font-light'>
                            burks.liam@gmail.com
                        </p>
                    </div>
                </div>
                <div className='flex-none'>
                        <div className='btn btn-circle'
                             onClick={() => props.onThemeChange(props.theme === 'light' ? 'dark' : 'light')}
                        >
                            {
                                props.theme === 'light' ? <MoonIcon className={'w-5 h-5'} /> :
                                    <SunIcon className='w-5 h-5' />
                            }
                        </div>
                </div>
            </div>
        </div>
        // <div className='w-full flex justify-center bg-base-200 drop-shadow z-50 relative'>
        //     <div className='navbar max-w-7xl'>
        //         <div className='flex w-full justify-between'>
        //             <details className='dropdown'>
        //                 <summary><PhoneIcon className={'w-6 h-6'}/></summary>
        //                 <ul className='p-2 drop-shadow menu dropdown-content z-[999] bg-base-100 rounded-box w-52'>
        //                     <li>
        //                         <div
        //                             className='flex items-center justify-start'
        //                             onClick={() => copyToClipboard('+27834176895')}
        //                         >
        //                             <a
        //                                 target={'_blank'}
        //                                 className='btn btn-circle btn-primary flex items-center justify-center normal-case'
        //                             >
        //                                 <PhoneIcon className='text-base-100 w-6 h-6' />
        //                             </a>
        //                             <div>
        //                                 <p className=''>
        //                                     Phone
        //                                 </p>
        //                                 <p className='text-sm font-light'>
        //                                     +27 83 417 6895
        //                                 </p>
        //                             </div>
        //                         </div>
        //                     </li>
        //
        //                     <li>
        //                         <div className='flex items-center justify-start'
        //                              onClick={() => copyToClipboard('burks.liam@gmail.com')}
        //                         >
        //                             <a
        //                                 target={'_blank'}
        //                                 className='btn btn-circle btn-accent flex items-center justify-center normal-case'
        //                             >
        //                                 <EnvelopeIcon className='text-base-100 w-6 h-6' />
        //                             </a>
        //                             <div>
        //                                 <p className=''>
        //                                     Email
        //                                 </p>
        //                                 <p className='text-sm font-light'>
        //                                     burks.liam@gmail.com
        //                                 </p>
        //                             </div>
        //                         </div>
        //                     </li>
        //                 </ul>
        //             </details>
        //
        //             <ul className='menu menu-horizontal px-2 gap-2'>
        //                 <li tabIndex={0}>
        //                     <a className="text-base">
        //                         <PaintBrushIcon className='w-5 h-5' />
        //                         <p className="hidden md:visible">
        //                             Change Theme
        //                         </p>
        //                     </a>
        //                     <ul className='p-2 bg-base-200 min-w-full -left-14'>
        //                         <li>
        //                             <div className="flex items-center justify-start"
        //                                  onClick={() => props.onThemeChange("light")}
        //                             >
        //                                 <SunIcon className='w-5 h-5' />
        //                                 <p className="">
        //                                     Light
        //                                 </p>
        //                             </div>
        //                         </li>
        //
        //                         <li>
        //                             <div className="flex items-center justify-start"
        //                                  onClick={() => props.onThemeChange("dark")}
        //                             >
        //                                 <MoonIcon className='w-5 h-5' />
        //                                 <p className="">
        //                                     Dark
        //                                 </p>
        //                             </div>
        //                         </li>
        //                     </ul>
        //                 </li>
        //             </ul>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Navbar;